/*-----------------------------------------------------------------------------------*/
/*  PORTFOLIO
/*-----------------------------------------------------------------------------------*/

@import 'loader';

#section-intro img{
  width: 100%;
}

/* .item { margin-bottom:30px; } */

.item { box-sizing: none; }

.item img {
  vertical-align: bottom;
  -webkit-transition: all 400ms ease-out;
  -moz-transition: all 400ms ease-out;
  -ms-transition: all 400ms ease-out;
  -o-transition: all 400ms ease-out;
  transition: all 400ms ease-out;
}

.item .project-title {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(32, 13, 48, 0.7);
  color: #fff;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  opacity: 0;
}

.item .item-inner .portfolio-item {
  display: block;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.item .item-inner .portfolio-item:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  /* border: 1px solid #ffffff; */
  opacity: 0;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}
.item .item-inner .portfolio-item h3 {
  color: #ffffff;
}

.item {
  .title-wrapper {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0 25px;
}
  .button-wrapper {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;

    @include media-breakpoint-down(xs) { bottom: 5px; }
  }
}

.item:hover:not(.single-portfolio) img {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}
.item:hover:not(.single-portfolio) .item-inner .portfolio-item:after {
  opacity: 1;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
}
.item:hover:not(.single-portfolio) .project-title {
  opacity: 1;
}
.item:hover:not(.single-portfolio) .title-wrapper {
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.item .item-inner,
.item .project-title {
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.item.full {
  padding: 20px 0 60px;
}

#projects .full .container-fluid {
  max-width: 100%;
  margin-top: 20px;
}

#projects {
  //min-height: 855px;
  display: none;
  height: auto;
}
/* Quick preview controls */
.controls {
  cursor: pointer;

  i {
    color:#757575;
  }

  span {
    opacity: 0;
    line-height: 27px;
    font-family: 'Ubuntu', sans-serif;
    color: #757575;
    font-size: 16px;
    margin: 0 5px;
    transition: all 0.4s ease-in;
  }

  &:hover span {
    opacity: 1;
  }
}

/* Project details */
.project-details ul {
  padding: 0;
  list-style-type: none;
}

.project-details li {
  color:#FFF;
  padding:7px 0 7px 0;
}

.project-details strong {
  display: block;
  text-transform: uppercase;
  color: #ffca05;
}

.project-details a { color:#FFF; }

.project-details h4 { color:#FFF; }

.client-overlay {
    background: linear-gradient(to bottom, rgba(32, 13, 48, 1), rgba(0, 0, 0, 0));
    position: absolute;
    top: 0;
    right: 15px;
    bottom: 0;
}

/* Project full preview */
.client-info {
    padding:35px 0px 35px 0px;
}

.projects-wrapper {
	/*  display:none; */
	/* height:2050px; */
}

/* fix да не залепва header-a и footer-a, понеже div-a за пълни с ajax */
.container-portfolio {
  min-height:1200px;
}

/*
.projects-items .item {
	max-width:33.33%;
}
*/
